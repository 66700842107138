<template>
  <LayoutAppContent>
    <div class="h-full overflow-auto" :class="{ 'bg-neutral-100': isAuthenticated }">
      <div class="">
        <!-- content -->
        <div class="mx-auto mb-5 mt-5 max-w-5xl px-2" v-if="isAuthenticated && !handlerNavItem.isAuthPending">
          <div
            class="mx-0 mb-4 mt-4 bg-sky-50 shadow sm:mx-5 sm:my-5 sm:rounded-lg md:mx-8 md:my-8 xl:mx-auto xl:max-w-5xl"
          >
            <!-- Card Content goes here -->
            <div class="justify-between px-4 py-5 sm:p-10 lg:flex">
              <div>
                <h1 class="text-2xl font-extrabold">Hazardous Waste System</h1>
                <h2 class="mt-2 text-xl text-neutral-500 md:mt-1">
                  <span class="font-semibold">Welcome, {{ userRef.firstName }}</span>
                </h2>
                <div class="prose prose-neutral mt-4 prose-li:marker:text-neutral-400 md:mt-5">
                  <p>
                    <router-link
                      :to="{ name: handlerNavItem.name }"
                      v-if="handlerNavItem.isAuthorized"
                      class="text-sky-500"
                    >
                      <span>View hazardous waste handler information</span></router-link
                    ><span v-else>View hazardous waste handler information</span>
                    managed in the Resource Conservation and Recovery Act Information (RCRAInfo) system. Handlers
                    include all generators, transporters, treaters, storers, and disposers of hazardous waste.
                  </p>
                </div>
              </div>
              <div class="mt-6 items-start justify-start gap-5 space-y-5 sm:flex sm:space-y-0 lg:mt-0">
                <LogoutButton :as="BaseButton" variant="priority-hollow" class="w-full sm:w-auto" label="Sign Out" />
              </div>
            </div>
            <div class="flex justify-center" v-if="false">
              <h2 class="mt-2 mb-4 text-xl text-sky-500 md:mt-1 md:mb-6">
                View all
                <span class="font-semibold">
                  <router-link :to="{ name: handlerNavItem.name }" v-if="handlerNavItem.isAuthorized">
                    <span class="cursor-pointer font-semibold hover:underline">{{ handlerNavItem.label }}</span>
                  </router-link></span
                >
              </h2>
            </div>
          </div>
          <div class="pb-5">
            <h3 class="text-base font-semibold leading-6 text-neutral-900">Active Handlers by Generator Status</h3>
            <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
              <router-link
                :to="{ name: routeNames.handlerList, query: { federalWasteGeneratorIn: [id], status: 'Active' } }"
                v-for="[id, item] in generatorsWithCounts"
                :key="id"
                class="rounded-lg bg-white px-4 py-5 shadow sm:p-6 border border-transparent transition-all hover:border-sky-200 hover:bg-sky-100 focus:border-sky-200 focus:bg-sky-100"
              >
                <dt class="truncate text-sm font-medium text-neutral-500">{{ item.longDescription }}</dt>
                <dd class="mt-1 text-3xl font-semibold tracking-tight text-neutral-900">{{ item.count }}</dd>
              </router-link>
            </dl>
          </div>

          <div>
            <h3 class="text-base font-semibold leading-6 text-neutral-900">Active Handlers by County</h3>
            <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
              <router-link
                :to="{ name: routeNames.handlerList, query: { countyCodeIn: [id], status: 'Active' } }"
                v-for="[id, item] in countyCodesWithCounts"
                :key="id"
                class="rounded-lg bg-white px-4 py-5 shadow sm:p-6 border border-transparent transition-all hover:border-sky-200 hover:bg-sky-100 focus:border-sky-200 focus:bg-sky-100"
              >
                <dt class="truncate text-sm font-medium text-neutral-500">{{ item.description }} County</dt>
                <dd class="mt-1 text-3xl font-semibold tracking-tight text-neutral-900">{{ item.count }}</dd>
              </router-link>
            </dl>
          </div>
        </div>

        <!-- Home features content for auth'd and unauth'd users alike. We toggle widgets based on auth status. (and eventually, user profile config) -->
        <div
          v-if="!isAuthenticated && !isInternal"
          class="mx-0 mb-4 mt-4 bg-sky-50 shadow sm:mx-5 sm:my-5 sm:rounded-lg md:mx-8 md:my-8 xl:mx-auto xl:max-w-5xl"
        >
          <!-- Card Content goes here -->
          <div class="justify-between px-4 py-5 sm:p-10 lg:flex">
            <div>
              <h1 class="text-2xl font-extrabold">
                Hazardous Waste System
                <!-- <span class="font-semibold">(Sustainability Management and Reporting Tool)</span> -->
              </h1>
              <h2 class="mt-2 text-xl text-sky-500 md:mt-1">
                <span class="font-semibold"><LoginButton class="hover:underline" /></span>
                <!-- or
                <RegisterButton class="cursor-pointer font-semibold hover:underline">Register</RegisterButton> -->
                to get started
              </h2>
              <div class="prose prose-neutral mt-4 prose-li:marker:text-neutral-400 md:mt-5">
                <p>
                  View hazardous waste handler information managed in the Resource Conservation and Recovery Act
                  Information (RCRAInfo) system. Handlers include all generators, transporters, treaters, storers, and
                  disposers of hazardous waste.
                </p>
              </div>
            </div>
            <div class="mt-6 items-start justify-start gap-5 space-y-5 sm:flex sm:space-y-0 lg:mt-0">
              <LoginButton :as="BaseButton" variant="priority-hollow" class="w-full sm:w-auto" label="Sign In" />
            </div>
          </div>
        </div>
      </div>
      <footer class="sticky top-[100vh] mt-20 flex h-10 flex-col items-center p-2">
        <MdrnAttribution />
      </footer>
    </div>
  </LayoutAppContent>
</template>

<script lang="ts">
import { InitialBadge } from "@modernary/mui/components/badge";
import { BaseButton } from "@modernary/mui/components/button";
import { MdrnAttribution, NoneFoundFlag, SkeletonButton, useAppNav } from "@modernary/mui/components/common";
import { LoginButton, LogoutButton, RegisterButton, useUser } from "@modernary/mui/utils/authentication";
import { useAuthorization } from "@modernary/mui/utils/authorization";

import { ref, computed, onMounted, watch, defineComponent } from "vue";
import { FaceSmileIcon, PlusIcon } from "@heroicons/vue/24/solid";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import { PhoneIcon, EnvelopeIcon } from "@heroicons/vue/20/solid";
import { enumsCopy } from "@/constants/enums";
import { getHandlers } from "@/views/handler/handlerApi";
import { routeNames } from "@/routes";

export default defineComponent({
  setup() {
    const { userRef } = useUser();

    const { authorizationClient, AuthRequest, handlePreempts } = useAuthorization();
    const { navigationItems, userNavigationItems } = useAppNav();

    type CountyCounts = Map<string, { id: string; description: string; count: number }>;
    const countyCodesWithCounts = ref(enumsCopy.countyCodes() as CountyCounts);

    type GeneratorCounts = Map<
      string,
      { id: string; description: string; longDescription: string; isFilter: boolean; count: number }
    >;
    const generatorsWithCounts = ref(enumsCopy.filterFederalWasteGeneratorTypes() as GeneratorCounts);

    const isInternal = computed(
      () => Boolean(userRef.value?.claims?.some((x: any) => x.type === "role" && x.value === "Internal")), //TODO:TS
    );

    const isAuthenticated = computed(() => userRef.value.isAuthenticated);
    const isExternalAuthenticated = computed(() => isAuthenticated?.value && !isInternal.value);

    //TODO: probably not the best approach.
    const handlerNavItem = computed(
      () => navigationItems.find((x) => x.label === "Handlers")!.items.find((x) => x.name === routeNames.handlers)!,
    );

    const hasReadHandlerAR = new AuthRequest();
    hasReadHandlerAR.permissionName = "ReadHandler";

    watch(
      isAuthenticated,
      () => {
        authorizationClient.isAuthorized(hasReadHandlerAR).then((hasReadHandler) => {
          if (hasReadHandler) {
            getCountyCounts();
            getGeneratorCounts();
          }
        }, handlePreempts);
      },
      { immediate: true },
    );

    const getCountyCounts = () =>
      countyCodesWithCounts.value.forEach((code) => {
        getHandlers({ countyCode: code.id, status: "Active", take: 1 }).then((result) => {
          code.count = result.data.totalResultsCount;
        });
      });

    const getGeneratorCounts = () => {
      generatorsWithCounts.value.forEach((type) => {
        getHandlers({ federalWasteGenerator: type.id, status: "Active", take: 1 }).then((result) => {
          type.count = result.data.totalResultsCount;
        });
      });
    };

    return {
      routeNames,
      countyCodesWithCounts,
      generatorsWithCounts,
      handlerNavItem,
      isExternalAuthenticated,
      isInternal,
      userRef,
      isAuthenticated,
      BaseButton,
    };
  },
  components: {
    SkeletonButton,
    BaseButton,
    InitialBadge,
    LoginButton,
    RegisterButton,
    PlusIcon,
    ChevronRightIcon,
    MdrnAttribution,
    FaceSmileIcon,
    NoneFoundFlag,
    PhoneIcon,
    EnvelopeIcon,
    LogoutButton,
  },
});
</script>
